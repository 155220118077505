import { getMetafieldValue } from 'helpers';
import {
    defaultPaidSampleQuantityLimitPerOrder,
    defaultSampleQuantityLimitPerOrder,
} from 'helpers/constants/product';
import { gShopifyProductIncludingSample } from 'helpers/graphql';
import { getShopifyProductByHandle } from 'helpers/requests/shopify-data-requests';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

const useSampleQuantityLimitPerOrder = ({ sampleVariantHandle, paidSampleVariantHandle }) => {
    const [sample, setSample] = useState({
        sampleProduct: undefined,
        sampleQuantityLimitPerOrder: undefined,
        paidSampleProduct: undefined,
        paidSampleQuantityLimitPerOrder: undefined,
    });
    const fetchProductData = variantHandle =>
        useQuery({
            queryKey: [variantHandle],
            queryFn: async () => await getShopifyProductByHandle(variantHandle),
            enabled: !!variantHandle,
        });

    const { data: sData, isFetching: sIsFetching } = fetchProductData(sampleVariantHandle);
    const { data: psData, isFetching: psIsFetching } = fetchProductData(paidSampleVariantHandle);

    const computeSampleData = (data, isFetching, quantityLimitPerOrder) => {
        if (isFetching) return null;

        const productRespData = data?.data;
        if (!productRespData) return null;

        const product = gShopifyProductIncludingSample(productRespData);
        if (!product) return null;

        const sampleQuantityLimitPerOrder = Number(
            getMetafieldValue(product.metafields || [], 'sample', 'quantity_limit_per_order') ||
                quantityLimitPerOrder
        );

        return {
            sampleProduct: product,
            sampleQuantityLimitPerOrder,
        };
    };

    useEffect(() => {
        const sComputedData = computeSampleData(
            sData,
            sIsFetching,
            defaultSampleQuantityLimitPerOrder
        );
        setSample(prevState => ({
            ...prevState,
            sampleProduct: sComputedData?.sampleProduct,
            sampleQuantityLimitPerOrder: sComputedData?.sampleQuantityLimitPerOrder,
        }));
    }, [sData, sIsFetching]);

    useEffect(() => {
        const psComputedData = computeSampleData(
            psData,
            psIsFetching,
            defaultPaidSampleQuantityLimitPerOrder
        );
        setSample(prevState => ({
            ...prevState,
            paidSampleProduct: psComputedData?.sampleProduct,
            // TODO: Enable when Zia want dynamic paid sample quantity limit per order
            // paidSampleQuantityLimitPerOrder: psComputedData?.sampleQuantityLimitPerOrder,
            paidSampleQuantityLimitPerOrder: defaultPaidSampleQuantityLimitPerOrder,
        }));
    }, [psData, psIsFetching]);

    return sample;
};

export default useSampleQuantityLimitPerOrder;
