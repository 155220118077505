import PropTypes from 'prop-types';

import { PDPIcon } from './PdpIcon.styled';

const Icon = ({ fill = 'currentColor', ...attrs }) => {
    return (
        <PDPIcon>
            <svg
                className="icon icon--size icon--marble3x12"
                width="158"
                viewBox="0 0 158 65"
                {...attrs}
                fill="none"
                stroke={fill}
                strokeWidth="1"
            >
                <polygon points="29.6,31.9 29.6,63.8 156.8,63.8 156.8,32.1" />
                <polyline points="156.8,28.5 156.8,21.4 29.6,21.4 29.6,28.5" />
                <polyline points="26.3,31.9 19.3,31.9 19.3,63.8 26.3,63.8" />
                <text transform="matrix(1 0 0 1 86.5275 14.289)">12”</text>
                <text transform="matrix(1 0 0 1 0.6645 55.4165)">3”</text>
            </svg>
        </PDPIcon>
    );
};
Icon.propTypes = {
    fill: PropTypes.string,
};

export default Icon;
