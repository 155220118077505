import PropTypes from 'prop-types';

import { PDPIcon } from './PdpIcon.styled';

const Icon = ({ fill = 'currentColor', ...attrs }) => {
    return (
        <PDPIcon>
            <svg
                className="icon icon--size icon--marble3x33x12"
                width="69"
                viewBox="0 0 69 194"
                {...attrs}
                fill="none"
                stroke={fill}
                strokeWidth="1"
            >
                <rect x="36.1" y="31" width="31.5" height="126.2" />
                <rect x="36.1" y="160.6" width="31.5" height="31.4" />
                <polyline points="67.7,27.2 67.7,20.4 35.8,20.4 35.8,27.2" />
                <polyline points="32.5,31 25.8,31 25.8,157.2 32.5,157.2" />
                <polyline points="32.5,160.4 25.8,160.4 25.8,192.3 32.5,192.3" />
                <text transform="matrix(1 0 0 1 46.8717 14.7564)">3”</text>
                <text transform="matrix(1 0 0 1 7.5389 184.0366)">3”</text>
                <text transform="matrix(1 0 0 1 0.85 99.662)">12”</text>
            </svg>
        </PDPIcon>
    );
};
Icon.propTypes = {
    fill: PropTypes.string,
};

export default Icon;
