import PropTypes from 'prop-types';

import { PDPIcon } from './PdpIcon.styled';

const Icon = ({ fill = 'currentColor', ...attrs }) => {
    return (
        <PDPIcon>
            <svg
                className="icon icon--size icon--marble6x12"
                width="136"
                viewBox="0 0 136 85"
                {...attrs}
                fill="none"
                stroke={fill}
                strokeWidth="1"
            >
                <polygon points="33.2,32.8 33.2,83.2 134.3,83.3 134.3,33" />
                <polyline points="134.3,27.7 134.3,22.2 33.2,22.2 33.2,27.7" />
                <polyline points="28.2,32.8 22.6,32.8 22.6,83.2 28.2,83.2" />
                <text transform="matrix(1 0 0 1 70.5907 14.8232)">12”</text>
                <text transform="matrix(1 0 0 1 1.2499 64.04)">6”</text>
            </svg>
        </PDPIcon>
    );
};
Icon.propTypes = {
    fill: PropTypes.string,
};

export default Icon;
