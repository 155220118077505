import PropTypes from 'prop-types';

import { PDPIcon } from './PdpIcon.styled';

const Icon = ({ fill = 'currentColor', ...attrs }) => {
    return (
        <PDPIcon>
            <svg
                className="icon icon--size icon--marble6x6diamond"
                width="192"
                viewBox="0 0 192 72"
                {...attrs}
                fill="none"
                stroke={fill}
                strokeWidth="1"
            >
                <polygon points="26.5,27.4 26.5,70.9 70,70.9 70,27.6" />
                <polyline points="70,25 70,20.2 26.5,20.2 26.5,25" />
                <polyline points="24.2,27.4 19.4,27.4 19.4,70.9 24.2,70.9" />
                <polygon points="101.1,33.1 145,33.1 122.7,70.9 78.9,70.9" />
                <polyline points="147,33.1 151.7,33.1 151.7,70.9 125,70.9" />
                <polyline points="101.1,30.6 101.1,26.1 144.7,26.1 144.7,30.6" />
                <text transform="matrix(1 0 0 1 117.3964 19.6688)">6”</text>
                <text transform="matrix(1 0 0 1 157.4324 54.8593)">5.25”</text>
                <text transform="matrix(1 0 0 1 43.4723 14.2161)">6”</text>
                <text transform="matrix(1 0 0 1 0.8234 53.2704)">6”</text>
            </svg>
        </PDPIcon>
    );
};
Icon.propTypes = {
    fill: PropTypes.string,
};

export default Icon;
