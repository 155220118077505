import { getInventoryStatus, getMetafieldValue, getTileSize, identifyShape } from 'helpers';
import { initialArea, initialOverage } from './constants/product';
function gShopifyVariant(variant) {
    return {
        availableForSale: variant.availableForSale,
        id: variant.id,
        title: variant.title,
        price: variant.price.amount,
        priceCurrency: variant.price.currencyCode,
        compareAtPrice: variant.compareAtPrice ? variant.compareAtPrice.amount : null,
        sku: variant.sku,
        hex: variant.metafield?.value ? variant.metafield?.value : null,
        selectedOptions: variant.selectedOptions,
        quantityAvailable: variant.quantityAvailable,
        ...(variant.image?.transformedSrc && {
            image: {
                src: variant.image.transformedSrc,
                altText: variant.image.altText,
            },
        }),
    };
}
function tShopifyProduct(base) {
    const selectedOrFirstAvailableVariant =
        base?.variants?.edges?.find(edge => edge.node.availableForSale)?.node ||
        base?.variants?.edges?.[0]?.node;
    const product = {
        id: base.id,
        title: base.title,
        titleLine1: getProductTitleLine1(base),
        titleLine2: getProductTitleLine2(base),
        handle: base.handle,
        vendor: base.vendor,
        productType: base.productType,
        collection: base.collections?.edges[0]?.node
            ? {
                  title: base.collections.edges[0].node.title,
                  handle: base.collections.edges[0].node.handle,
                  products: base.collections.edges[0].node?.products?.edges?.map(({ node }) => ({
                      title: node.title,
                      handle: node.handle,
                      images: node.images?.edges?.map(image => ({
                          src: image.node.transformedSrc,
                          altText: image.node.altText,
                      })),
                      price: node.priceRange.maxVariantPrice.amount,
                      metafields: node.metafields,
                      selectedOrFirstAvailableVariant: {
                          ...gShopifyVariant(
                              node?.variants?.edges?.find(edge => edge.node.availableForSale)
                                  ?.node || node?.variants?.edges?.[0]?.node
                          ),
                      },
                      collection: node.collections?.edges?.[0]?.node
                          ? {
                                title: node.collections.edges[0].node.title,
                            }
                          : null,
                  })),
              }
            : null,
        collections:
            base?.collections?.edges?.map(edge => ({
                title: edge.node.title,
                handle: edge.node.handle,
            })) || [],
        collectionsHandle: base.collections?.edges[0]?.node
            ? base.collections.edges.map(edge => edge.node.handle)
            : [],
        description: base.descriptionHtml,
        tags: base.tags,
        images: base.images?.edges?.map(image => ({
            originalSrc: image.node.url,
            src: image.node.transformedSrc,
            altText: image.node.altText,
            width: image.node.width,
            height: image.node.height,
        })),
        metafields: base.metafields,
        selectedOrFirstAvailableVariant: {
            ...gShopifyVariant(selectedOrFirstAvailableVariant),
        },
        variants: base.variants.edges.map(v => ({
            availableForSale: v.node.availableForSale,
            id: v.node.id,
            title: v.node.title,
            price: v.node.price.amount,
            priceCurrency: v.node.price.currencyCode,
            compareAtPrice: v.node.compareAtPrice ? v.node.compareAtPrice.amount : null,
            sku: v.node.sku,
            hex: v.node.metafield?.value ? v.node.metafield?.value : null,
            selectedOptions: v.node.selectedOptions,
            quantityAvailable: v.node.quantityAvailable,
            ...(v.node?.image?.transformedSrc && {
                image: {
                    src: v.node.image.transformedSrc,
                    altText: v.node.image.altText,
                },
            }),
        })),
        seo: {
            title: base?.seo?.title || '',
            description: base?.seo?.description || '',
        },
    };
    return product;
}
export function gShopifyProductIncludingSample(graphqlResult) {
    const base = graphqlResult.product;
    if (!base) return null;
    return tShopifyProduct(base);
}
function gShopifyProduct(graphqlResult) {
    const base = graphqlResult.product;

    if (!base || base.title?.toLowerCase()?.includes(', sample')) {
        return null;
    }
    return tShopifyProduct(base);
}

export function transformProductCard(product, inventoryQuantityThreshold) {
    const selectedOrFirstAvailableVariant =
        product.selectedOrFirstAvailableVariant ||
        product?.variants?.find(variant => variant.availableForSale) ||
        product?.variants?.[0];
    const template = product?.metafields?.find(mf => mf?.key === 'template')?.value || 'default';
    const forceInventoryStatus =
        product?.metafields?.find(
            mf => mf?.namespace === 'customAttributes' && mf?.key === 'select_lead_time'
        )?.value || null;
    const quantityAvailable = selectedOrFirstAvailableVariant.quantityAvailable;
    const nonTilePDP =
        product.metafields.find(mf => mf?.key === 'non_tile_pdp')?.value === 'false' ||
        !product.metafields.find(mf => mf?.key === 'non_tile_pdp')?.value
            ? false
            : true;

    const quantity =
        template === 'base-molding'
            ? Math.ceil(
                  (initialArea /
                      Number(
                          product.metafields?.find(mf => mf?.key === 'linear_feet_box_ratio')?.value
                      )) *
                      Number(initialOverage)
              )
            : template === 'barliner'
            ? Math.ceil(initialArea * Number(initialOverage) * 2)
            : nonTilePDP
            ? 1
            : Math.ceil(
                  (initialArea /
                      Number(
                          product?.metafields?.find(mf => mf?.key === 'square_feet_box_ratio')
                              ?.value
                      )) *
                      Number(initialOverage)
              );

    const leadTime = getInventoryStatus(
        quantity,
        quantityAvailable,
        inventoryQuantityThreshold,
        forceInventoryStatus
    );

    const props = {
        imgWidth: 315,
        imgHeight: 315,
        imgLoading: 'lazy',
        sizes: `(min-width: 1280px) 25vw,
          (min-width: 768px) 33vw,
          100vw
        `,
        title: product.title,
        handle: product.handle,
        images: product.images.slice(0, 2),
        leadTime,
        price: selectedOrFirstAvailableVariant.price || null,
        collectionTitle: product.productType,
        length: product.metafields?.find(mf => mf?.key === 'length')?.value,
        width: product.metafields?.find(mf => mf?.key === 'width')?.value,
        shape: product.metafields?.find(mf => mf?.key === 'shape')?.value,
        isMosaic: getMetafieldValue(product.metafields, 'heading', 'is_mosaic') === 'true',
        nonTilePDP:
            product.metafields?.find(mf => mf?.key === 'non_tile_pdp')?.value === 'false' ||
            !product.metafields?.find(mf => mf?.key === 'non_tile_pdp')?.value
                ? false
                : true,
        template,
        ...(template === 'base-molding' && {
            linearftBoxRatio: product.metafields?.find(mf => mf?.key === 'linear_feet_box_ratio')
                ?.value,
        }),
        ...(template === 'default' && {
            sqftBoxRatio: parseFloat(
                product.metafields?.find(mf => mf?.key === 'square_feet_box_ratio')?.value
            ),
        }),
        compareAtPrice: selectedOrFirstAvailableVariant.compareAtPrice || null,
        productId: product.id,
        variantId: selectedOrFirstAvailableVariant.id,
        vendor: product.vendor,
        collections: product.collections,
        variantTitle: selectedOrFirstAvailableVariant.title,
        list: `/collections/${product?.collection?.handle || ''}`,
        variantSku: selectedOrFirstAvailableVariant.sku,
    };
    return props;
}
export function transformAdminProductCard(product, inventoryQuantityThreshold) {
    const selectedOrFirstAvailableVariant =
        product.selectedOrFirstAvailableVariant ||
        product?.variants?.find(variant => variant.availableForSale) ||
        product?.variants?.[0];
    const template =
        product?.metafields?.find(mf => mf?.key === 'accentuate.template')?.value || 'default';
    const forceInventoryStatus =
        product?.metafields?.find(mf => mf?.key === 'customAttributes.select_lead_time')?.value ||
        null;
    const quantityAvailable = selectedOrFirstAvailableVariant.quantityAvailable;
    const nonTilePDP =
        product.metafields.find(mf => mf?.key === 'accentuate.non_tile_pdp')?.value === 'false' ||
        !product.metafields.find(mf => mf?.key === 'accentuate.non_tile_pdp')?.value
            ? false
            : true;

    const quantity =
        template === 'base-molding'
            ? Math.ceil(
                  (initialArea /
                      Number(
                          product.metafields?.find(
                              mf => mf?.key === 'accentuate.linear_feet_box_ratio'
                          )?.value
                      )) *
                      Number(initialOverage)
              )
            : template === 'barliner'
            ? Math.ceil(initialArea * Number(initialOverage) * 2)
            : nonTilePDP
            ? 1
            : Math.ceil(
                  (initialArea /
                      Number(
                          product?.metafields?.find(
                              mf => mf?.key === 'accentuate.square_feet_box_ratio'
                          )?.value
                      )) *
                      Number(initialOverage)
              );

    const leadTime = getInventoryStatus(
        quantity,
        quantityAvailable,
        inventoryQuantityThreshold,
        forceInventoryStatus
    );

    const props = {
        imgWidth: 315,
        imgHeight: 315,
        imgLoading: 'lazy',
        sizes: `(min-width: 1280px) 25vw,
          (min-width: 768px) 33vw,
          100vw
        `,
        title: product.title,
        handle: product.handle,
        images: product.images.slice(0, 2),
        leadTime,
        price: selectedOrFirstAvailableVariant.price || null,
        collectionTitle: product.productType,
        length: product.metafields?.find(mf => mf?.key === 'accentuate.length')?.value,
        width: product.metafields?.find(mf => mf?.key === 'accentuate.width')?.value,
        shape: product.metafields?.find(mf => mf?.key === 'accentuate.shape')?.value,
        isMosaic: product.metafields?.find(mf => mf?.key === 'heading.is_mosaic')?.value === 'true',
        nonTilePDP:
            product.metafields?.find(mf => mf?.key === 'accentuate.non_tile_pdp')?.value ===
                'false' ||
            !product.metafields?.find(mf => mf?.key === 'accentuate.non_tile_pdp')?.value
                ? false
                : true,
        template,
        ...(template === 'base-molding' && {
            linearftBoxRatio: product.metafields?.find(
                mf => mf?.key === 'accentuate.linear_feet_box_ratio'
            )?.value,
        }),
        ...(template === 'default' && {
            sqftBoxRatio: parseFloat(
                product.metafields?.find(mf => mf?.key === 'accentuate.square_feet_box_ratio')
                    ?.value
            ),
        }),
        compareAtPrice: selectedOrFirstAvailableVariant.compareAtPrice || null,
        productId: product.id,
        variantId: selectedOrFirstAvailableVariant.id,
        vendor: product.vendor,
        collections: product.collections,
        variantTitle: selectedOrFirstAvailableVariant.title,
        list: `/collections/${product?.collection?.handle || ''}`,
        variantSku: selectedOrFirstAvailableVariant.sku,
    };
    return props;
}

export function transformAlgoliaProductCard(hit, inventoryQuantityThreshold) {
    const {
        handle,
        id,
        meta,
        product_image,
        title,
        variants_min_price,
        product_type,
        inventory_quantity,
        variants_compare_at_price_min,
        vendor,
        variant_title,
        __position,
        sku,
        objectID,
    } = { ...hit };
    const template = meta.accentuate?.template || 'default';
    const forceInventoryStatus = meta?.customAttributes?.select_lead_time || null;
    const nonTilePDP =
        meta.accentuate.non_tile_pdp === 'false' || !meta.accentuate.non_tile_pdp ? false : true;

    const quantity =
        template === 'base-molding'
            ? Math.ceil(
                  (initialArea / Number(meta.accentuate?.linear_feet_box_ratio)) *
                      Number(initialOverage)
              )
            : template === 'barliner'
            ? Math.ceil(initialArea * Number(initialOverage) * 2)
            : nonTilePDP
            ? 1
            : Math.ceil(
                  (initialArea / Number(meta.accentuate?.square_feet_box_ratio)) *
                      Number(initialOverage)
              );

    const leadTime = getInventoryStatus(
        quantity,
        inventory_quantity,
        inventoryQuantityThreshold,
        forceInventoryStatus
    );

    const props = {
        padding: '0 20px',
        imgWidth: 315,
        imgHeight: 315,
        imgLoading: 'lazy',
        title,
        handle,
        images: [...(product_image ? [{ src: product_image }] : [])],
        price: variants_min_price,
        length: meta.accentuate?.length || null,
        width: meta.accentuate?.width || null,
        shape: meta.accentuate?.shape || null,
        nonTilePDP:
            meta.accentuate?.non_tile_pdp === 'false' || !meta.accentuate?.non_tile_pdp
                ? false
                : true,
        template,
        ...(template === 'base-molding' && {
            linearftBoxRatio: parseFloat(meta.accentuate?.linear_feet_box_ratio) || null,
        }),
        ...(template === 'default' && {
            sqftBoxRatio: parseFloat(meta.accentuate?.square_feet_box_ratio) || null,
        }),
        collectionTitle: product_type,
        leadTime,
        compareAtPrice: variants_compare_at_price_min,
        productId: id,
        variantId: objectID,
        vendor,
        variantTitle: variant_title,
        position: __position,
        list: '/search',
        variantSku: sku,
    };

    return props;
}

// cleans up graphql response from Shopify for a collection by handle
function gShopifyCollection(graphqlResult) {
    if (!graphqlResult?.collection) {
        return null;
    }
    const base = graphqlResult.collection.products.edges;
    const { title, description, handle, id, metafields, seo } = { ...graphqlResult.collection };
    const products = base.map(product => {
        const selectedOrFirstAvailableVariant =
            product?.node?.variants?.edges?.find(edge => edge.node.availableForSale)?.node ||
            product?.node?.variants?.edges?.[0]?.node;
        return {
            id: product.node.id,
            availableForSale: selectedOrFirstAvailableVariant.availableForSale,
            handle: product.node.handle,
            images: product.node.images?.edges?.map(image => ({
                src: image.node.transformedSrc,
                altText: image.node.altText,
            })),
            title: product.node.title,
            tags: product.node.tags,
            price: selectedOrFirstAvailableVariant.price.amount,
            compareAtPrice: selectedOrFirstAvailableVariant.compareAtPrice?.amount || null,
            metafields: product.node.metafields,
            vendor: product.node.vendor,
            productType: product.node.productType,
            selectedOrFirstAvailableVariant: {
                ...gShopifyVariant(selectedOrFirstAvailableVariant),
            },
            variants: product?.node?.variants?.edges?.map(v => ({
                availableForSale: v.node.availableForSale,
                id: v.node.id,
                title: v.node.title,
                price: v.node.price.amount,
                priceCurrency: v.node.price.currencyCode,
                compareAtPrice: v.node.compareAtPrice ? v.node.compareAtPrice.amount : null,
                sku: v.node.sku,
                hex: v.node.metafield?.value ? v.node.metafield?.value : null,
                selectedOptions: v.node.selectedOptions,
                quantityAvailable: v.node.quantityAvailable,
                ...(v.node.image?.transformedSrc && {
                    image: {
                        src: v.node.image.transformedSrc,
                        altText: v.node.image.altText,
                    },
                }),
            })),
            collections: product?.node?.collections?.edges?.map(edge => edge.node),
        };
    });
    return {
        title,
        description,
        handle,
        id,
        products,
        metafields,
        productsPageInfo: graphqlResult.collection.products.pageInfo,
        seo: {
            title: seo?.title || null,
            description: seo?.description || null,
        },
    };
}

// cleans up graphql response from Shopify for a products by id
function gShopifyProducts(graphqlResult) {
    if (graphqlResult?.nodes?.length) {
        const products = graphqlResult.nodes.map(product => {
            if (!product) {
                return null;
            }
            const selectedOrFirstAvailableVariant =
                product?.variants?.edges?.find(edge => edge.node.availableForSale)?.node ||
                product?.variants?.edges?.[0]?.node;
            return {
                id: product.id,
                handle: product.handle,
                images: product.images.edges.map(image => image.node),
                title: product.title,
                price: selectedOrFirstAvailableVariant.price.amount,
                metafields: product.metafields,
                selectedOrFirstAvailableVariant: {
                    ...gShopifyVariant(selectedOrFirstAvailableVariant),
                },
            };
        });
        return [...products];
    }

    return [];
}
function gShopifyCartProduct(graphqlResult) {
    const base = graphqlResult.product;
    const firstVariant = base.variants.edges[0].node;
    const product = {
        title: getProductTitleLine1(base) || base.title,
        handle: base.handle,
        id: base.id,
        upsellCartNote: getMetafieldValue(base.metafields, 'upsell', 'cart_note') || null,
        firstVariant: {
            id: firstVariant.id,
            sku: firstVariant.sku,
            title: firstVariant.title,
            price: firstVariant.price.amount,
            compareAtPrice: firstVariant.compareAtPrice?.amount || null,
            image: {
                src: firstVariant?.image.transformedSrc || null,
                altText: firstVariant?.image.altText || '',
            },
        },
        collections: base?.collections?.edges?.map(edge => edge.node),
    };
    return product;
}

function gShopifyProductSample(graphqlResult) {
    const base = graphqlResult?.product;

    if (!base) {
        return null;
    }

    const firstVariant = base.variants.edges[0].node;
    const product = {
        title: getProductTitleLine1(base) || base.title,
        handle: base.handle,
        id: base.id,
        firstVariant: {
            id: firstVariant.id,
            sku: firstVariant.sku,
            title: firstVariant.title,
            availableForSale: firstVariant.availableForSale,
            quantityAvailable: firstVariant.quantityAvailable,
        },
    };
    return product;
}

const getProductTitleLine1 = product => {
    const titleLine1 =
        getMetafieldValue(product.metafields, 'accentuate', 'h1_primary_title') || null;

    return titleLine1;
};
const getProductTitleLine2 = product => {
    const titleLine1 = getMetafieldValue(product.metafields, 'accentuate', 'h1_primary_title');
    const headingConvention = getMetafieldValue(product.metafields, 'heading', 'convention');

    if (!titleLine1 || !headingConvention) return '';

    let titleLine2 = '';

    switch (headingConvention) {
        case '1': {
            const isMosaic =
                getMetafieldValue(product.metafields, 'heading', 'is_mosaic') === 'true';
            const shape = getMetafieldValue(product.metafields, 'accentuate', 'shape');
            const productType = product.productType;
            titleLine2 = `${isMosaic ? 'Mosaic' : identifyShape(shape) || ''} ${productType || ''}`;
            break;
        }
        case '2': {
            const isMosaic =
                getMetafieldValue(product.metafields, 'heading', 'is_mosaic') === 'true';
            const shape = getMetafieldValue(product.metafields, 'accentuate', 'shape');
            const productType = product.productType;
            const color = getMetafieldValue(product.metafields, 'options', 'color');
            titleLine2 = `${isMosaic ? 'Mosaic' : identifyShape(shape) || ''} ${productType || ''}${
                color ? ` | ${color}` : ''
            }`;
            break;
        }
        case '3': {
            const length = getMetafieldValue(product.metafields, 'accentuate', 'length');
            const width = getMetafieldValue(product.metafields, 'accentuate', 'width');
            const productType3 = product.productType;
            titleLine2 = `${length && width ? `${length}" x ${width}"` : ''} ${productType3 || ''}`;
            break;
        }
        case '4': {
            const isMosaic =
                getMetafieldValue(product.metafields, 'heading', 'is_mosaic') === 'true';
            const length = getMetafieldValue(product.metafields, 'accentuate', 'length');
            const width = getMetafieldValue(product.metafields, 'accentuate', 'width');
            const shape = getMetafieldValue(product.metafields, 'accentuate', 'shape');
            titleLine2 = `${getTileSize({ length: length, width: width, shape: shape })} ${
                isMosaic ? 'Mosaic' : identifyShape(shape)
            }`;
            break;
        }
    }

    return titleLine2?.trimStart() || null;
};

export function transformAlsoComesInProduct(base) {
    if (base.title?.toLowerCase()?.includes(', sample')) {
        return null;
    }
    const selectedOrFirstAvailableVariant =
        base?.variants?.edges?.find(edge => edge.node.availableForSale)?.node ||
        base?.variants?.edges?.[0]?.node;
    const product = {
        id: base.id,
        title: base.title,
        handle: base.handle,
        vendor: base.vendor,
        productType: base.productType,
        collection: base.collections?.edges[0]?.node
            ? {
                  title: base.collections.edges[0].node.title,
                  handle: base.collections.edges[0].node.handle,
              }
            : null,
        collectionsHandle: base.collections?.edges[0]?.node
            ? base.collections.edges.map(edge => edge.node.handle)
            : [],
        tags: base.tags,
        images: base.images.edges.map(image => ({
            originalSrc: image.node.url,
            src: image.node.urlTransformed,
            altText: image.node.altText,
        })),
        metafields: base.metafields.edges?.map(edge => edge.node),
        selectedOrFirstAvailableVariant: {
            availableForSale: selectedOrFirstAvailableVariant.availableForSale,
            id: selectedOrFirstAvailableVariant.id,
            title: selectedOrFirstAvailableVariant.title,
            price: selectedOrFirstAvailableVariant.price,
            compareAtPrice: selectedOrFirstAvailableVariant.compareAtPrice,
            sku: selectedOrFirstAvailableVariant.sku,
            hex: selectedOrFirstAvailableVariant.metafield?.value
                ? selectedOrFirstAvailableVariant.metafield?.value
                : null,
            selectedOptions: selectedOrFirstAvailableVariant.selectedOptions,
            quantityAvailable: selectedOrFirstAvailableVariant.quantityAvailable,
        },
        variants: base.variants.edges.map(v => ({
            availableForSale: v.node.availableForSale,
            id: v.node.id,
            title: v.node.title,
            price: v.node.price,
            compareAtPrice: v.node.compareAtPrice,
            sku: v.node.sku,
            hex: v.node.metafield?.value ? v.node.metafield?.value : null,
            selectedOptions: v.node.selectedOptions,
            quantityAvailable: v.node.quantityAvailable,
        })),
    };
    return product;
}
export {
    gShopifyProduct,
    gShopifyProductSample,
    gShopifyCollection,
    gShopifyProducts,
    gShopifyCartProduct,
};
