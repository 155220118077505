import PropTypes from 'prop-types';

import { PDPIcon } from './PdpIcon.styled';

const Icon = ({ fill = 'currentColor', ...attrs }) => {
    return (
        <PDPIcon>
            <svg
                className="icon icon--size icon--marble12x126x126x6"
                width="194"
                viewBox="0 0 194 191"
                {...attrs}
                fill="none"
                stroke={fill}
                strokeWidth="1"
            >
                <rect x="34.1" y="137.9" width="103.9" height="51.8" />
                <rect x="34.1" y="31.1" width="103.9" height="103.9" />
                <rect x="140.7" y="31.1" width="52" height="103.9" />
                <rect x="140.8" y="137.9" width="51.9" height="51.8" />
                <polyline points="140.7,28.3 140.7,22.7 192.7,22.7 192.7,28.3" />
                <polyline points="34.1,28.3 34.1,22.7 138,22.7 138,28.3" />
                <polyline points="31.2,31.1 25.7,31.1 25.7,135.1 31.2,135.1" />
                <polyline points="31.2,137.9 25.7,137.9 25.7,189.7 31.2,189.7" />
                <text transform="matrix(1 0 0 1 159.6199 15.5418)">6”</text>
                <text transform="matrix(1 0 0 1 78.7068 14.3879)">12”</text>
                <text transform="matrix(1 0 0 1 0.7926 85.0623)">12”</text>
                <text transform="matrix(1 0 0 1 6.5145 169.0284)">6”</text>
            </svg>
        </PDPIcon>
    );
};
Icon.propTypes = {
    fill: PropTypes.string,
};

export default Icon;
