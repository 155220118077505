import axios from 'axios';
import { SHOP_NAME, STOREFRONT_ACCESS_TOKEN } from 'helpers/constants/urls';

export const getShopifyCollections = async () => {
    const query = `
      {
        collections(first: 250) {
          edges {
            node {
                title
                handle
            }
          }
        }
      }
   `;
    const { data } = await axios.post(
        `https://${SHOP_NAME}.myshopify.com/api/2024-04/graphql.json`,
        { query },
        {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'X-Shopify-Storefront-Access-Token': STOREFRONT_ACCESS_TOKEN,
            },
        }
    );

    return { ...data };
};

export const getShopifyCollectionByHandle = async ({ handle, after = null, first = 250 }) => {
    const query = `
        query getCollectionByHandle($handle: String!, $after: String, $first: Int) {
            collection(handle: $handle) {
                title
                description
                handle
                id
                metafields(
                    identifiers: [
                        { namespace: "color", key: "color_theme" }
                        { namespace: "accentuate", key: "filter_collection" }
                        { namespace: "accentuate", key: "filter_image" }
                        { namespace: "accentuate", key: "lower_description_body" }
                        { namespace: "accentuate", key: "lower_description_title" }
                    ]
                ) {
                    namespace
                    key
                    value
                }
                products(after: $after, first: $first) {
                    pageInfo {
                        endCursor
                        hasNextPage
                    }
                    edges {
                        node {
                            id
                            descriptionHtml
                            tags
                            title
                            handle
                            vendor
                            productType
                            metafields(
                                identifiers: [
                                    { namespace: "accentuate", key: "h1_primary_title" }
                                    { namespace: "heading", key: "convention" }
                                    { namespace: "heading", key: "is_mosaic" }
                                    { namespace: "accentuate", key: "is_sample" }
                                    { namespace: "conspire", key: "sample_variant" }
                                    { namespace: "conspire" key: "disable_sample_variant" }
                                    { namespace: "accentuate", key: "template" }
                                    { namespace: "options", key: "color" }
                                    { namespace: "accentuate", key: "acrgowise_product_code" }
                                    { namespace: "customAttributes", key: "subtitle" }
                                    { namespace: "accentuate", key: "non_tile_pdp" }
                                    { namespace: "accentuate", key: "shipping_dimensions" }
                                    { namespace: "accentuate", key: "tiles_per_box" }
                                    { namespace: "accentuate", key: "split_section_color" }
                                    { namespace: "accentuate", key: "square_feet_box_ratio" }
                                    { namespace: "accentuate", key: "linear_feet_box_ratio" }
                                    { namespace: "accentuate", key: "shape" }
                                    { namespace: "accentuate", key: "length" }
                                    { namespace: "accentuate", key: "length_short" }
                                    { namespace: "accentuate", key: "width" }
                                    { namespace: "accentuate", key: "thickness" }
                                    { namespace: "customAttributes", key: "select_lead_time" }
                                    { namespace: "customAttributes", key: "lead_times" }
                                    { namespace: "customAttributes", key: "lead_time" }
                                    { namespace: "recd_prod", key: "tag" }
                                    { namespace: "recd_prod", key: "products" }
                                    { namespace: "accentuate", key: "related_products_custom" }
                                ]
                            ) {
                                namespace
                                key
                                value
                            }
                            images(first: 5){
                                edges {
                                    node {
                                        originalSrc
                                        transformedSrc(maxWidth: 900)
                                        altText
                                    }
                                }
                            }
                            collections(first: 5) {
                                edges {
                                    node {
                                        handle
                                        title
                                    }
                                }
                            }
                            seo {
                                title
                                description
                            }
                            variants(first:25){
                                edges {
                                    node {
                                        availableForSale
                                        title
                                        id
                                        sku
                                        metafield (namespace: "accentuate", key: "hex") {
                                            value
                                        }
                                        price {
                                            amount
                                            currencyCode
                                        }
                                        compareAtPrice {
                                            amount
                                            currencyCode
                                        }
                                        selectedOptions {
                                            name
                                            value
                                        }
                                        quantityAvailable
                                        image {
                                            originalSrc
                                            transformedSrc(maxWidth: 900)
                                            altText
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                seo {
                  title
                  description
                }
            }
        }
   `;

    const variables = {
        handle,
        after,
        first,
    };

    const { data } = await axios.post(
        `https://${SHOP_NAME}.myshopify.com/api/2024-04/graphql.json`,
        { query, variables },
        {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'X-Shopify-Storefront-Access-Token': STOREFRONT_ACCESS_TOKEN,
            },
        }
    );

    return {
        ...data,
    };
};

export const getShopifyProducts = async () => {
    let gettingProducts = true,
        productsArr = [],
        cursor;
    while (gettingProducts) {
        const query = `
          {
            products(first: 250${cursor ? `, after: "${cursor}"` : ''}, query: "-title:sample") {
              pageInfo {
                endCursor
                hasNextPage
              }
              edges {
                cursor
                node {
                    title
                    handle
                }
              }
            }
          }
       `;
        const resp = await axios.post(
            `https://${SHOP_NAME}.myshopify.com/api/2024-04/graphql.json`,
            { query },
            {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'X-Shopify-Storefront-Access-Token': STOREFRONT_ACCESS_TOKEN,
                },
            }
        );
        const { pageInfo, edges } = resp.data.data.products;
        productsArr = productsArr.concat(
            edges.map(e => ({ title: e.node.title, handle: e.node.handle }))
        );
        if (pageInfo.hasNextPage) {
            cursor = pageInfo.endCursor;
        } else {
            gettingProducts = false;
        }
    }
    return productsArr;
};

export const getShopifyProductByHandle = async handle => {
    const query = `
        query getProductByHandle($handle: String!){
            product(handle: $handle) {
                id
                descriptionHtml
                tags
                title
                handle
                vendor
                productType
                metafields(
                    identifiers: [
                        { namespace: "accentuate", key: "h1_primary_title" }
                        { namespace: "heading", key: "convention" }
                        { namespace: "heading", key: "is_mosaic" }
                        { namespace: "accentuate", key: "is_sample" }
                        { namespace: "sample", key: "quantity_limit_per_order" }
                        { namespace: "sample", key: "quantity_limit_popup_title" }
                        { namespace: "sample", key: "quantity_limit_popup_text" }
                        { namespace: "conspire", key: "sample_variant" }
                        { namespace: "conspire", key: "paid_sample_variant" }
                        { namespace: "conspire" key: "disable_sample_variant" }
                        { namespace: "accentuate", key: "template" }
                        { namespace: "options", key: "color" }
                        { namespace: "accentuate", key: "acrgowise_product_code" }
                        { namespace: "customAttributes", key: "subtitle" }
                        { namespace: "accentuate", key: "non_tile_pdp" }
                        { namespace: "accentuate", key: "minimum_coverage" }
                        { namespace: "accentuate", key: "shipping_dimensions" }
                        { namespace: "accentuate", key: "tiles_per_box" }
                        { namespace: "accentuate", key: "split_section_color" }
                        { namespace: "accentuate", key: "square_feet_box_ratio" }
                        { namespace: "accentuate", key: "linear_feet_box_ratio" }
                        { namespace: "accentuate", key: "shape" }
                        { namespace: "accentuate", key: "length" }
                        { namespace: "accentuate", key: "length_short" }
                        { namespace: "accentuate", key: "width" }
                        { namespace: "accentuate", key: "thickness" }
                        { namespace: "customAttributes", key: "select_lead_time" }
                        { namespace: "customAttributes", key: "lead_times" }
                        { namespace: "customAttributes", key: "lead_time" }
                        { namespace: "recd_prod", key: "tag" }
                        { namespace: "recd_prod", key: "products" }
                        { namespace: "accentuate", key: "related_products_custom" }
                        { namespace: "also_comes_in", key: "disabled" }
                    ]
                ) {
                    namespace
                    key
                    value
                }
                images(first: 5){
                    edges {
                        node {
                            url
                            transformedSrc(maxWidth: 900)
                            altText
                            width
                            height
                        }
                    }
                }
                collections(first: 250){
                    edges {
                        node {
                            title
                            handle
                            products (first: 8) {
                                edges {
                                    node {
                                        title
                                        handle
                                        images (first: 2) {
                                            edges {
                                                node {
                                                    originalSrc
                                                    transformedSrc(maxWidth: 900)
                                                    altText
                                                }
                                            }
                                        }
                                        metafields(
                                            identifiers: [
                                                { namespace: "accentuate", key: "is_sample" }
                                                { namespace: "conspire", key: "sample_variant" }
                                                { namespace: "conspire" key: "disable_sample_variant" }
                                                { namespace: "accentuate", key: "template" }
                                                { namespace: "options", key: "color" }
                                                { namespace: "accentuate", key: "acrgowise_product_code" }
                                                { namespace: "customAttributes", key: "subtitle" }
                                                { namespace: "accentuate", key: "non_tile_pdp" }
                                                { namespace: "accentuate", key: "shipping_dimensions" }
                                                { namespace: "accentuate", key: "tiles_per_box" }
                                                { namespace: "accentuate", key: "split_section_color" }
                                                { namespace: "accentuate", key: "square_feet_box_ratio" }
                                                { namespace: "accentuate", key: "linear_feet_box_ratio" }
                                                { namespace: "accentuate", key: "shape" }
                                                { namespace: "accentuate", key: "length" }
                                                { namespace: "accentuate", key: "width" }
                                                { namespace: "accentuate", key: "thickness" }
                                                { namespace: "customAttributes", key: "select_lead_time" }
                                                { namespace: "customAttributes", key: "lead_times" }
                                            ]
                                        ) {
                                            namespace
                                            key
                                            value
                                        }
                                        priceRange {
                                            maxVariantPrice {
                                                amount
                                            }
                                        }
                                        variants(first:25){
                                            edges {
                                                node {
                                                    availableForSale
                                                    title
                                                    id
                                                    sku
                                                    metafield (namespace: "accentuate", key: "hex") {
                                                        value
                                                    }
                                                    price {
                                                        amount
                                                        currencyCode
                                                    }
                                                    compareAtPrice {
                                                        amount
                                                        currencyCode
                                                    }
                                                    selectedOptions {
                                                        name
                                                        value
                                                    }
                                                    quantityAvailable
                                                    image {
                                                        originalSrc
                                                        transformedSrc(maxWidth: 900)
                                                        altText
                                                    }
                                                }
                                            }
                                        }
                                        collections(first: 1){
                                            edges {
                                                node {
                                                    title
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                seo {
                    title
                    description
                }
                variants(first:25){
                    edges {
                        node {
                            availableForSale
                            title
                            id
                            sku
                            metafield (namespace: "accentuate", key: "hex") {
                                value
                            }
                            price {
                                amount
                                currencyCode
                            }
                            compareAtPrice {
                                amount
                                currencyCode
                            }
                            selectedOptions {
                                name
                                value
                            }
                            quantityAvailable
                            image {
                                originalSrc
                                transformedSrc(maxWidth: 900)
                                altText
                            }
                        }
                    }
                }
            }
        }
   `;

    const variables = {
        handle,
    };

    const { data } = await axios.post(
        `https://${SHOP_NAME}.myshopify.com/api/2024-04/graphql.json`,
        { query, variables },
        {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'X-Shopify-Storefront-Access-Token': STOREFRONT_ACCESS_TOKEN,
            },
        }
    );

    return {
        ...data,
    };
};

export const getShopifyProductsByIds = async ids => {
    const query = `
        query getShopifyProductsByIds($ids: [ID!]!){
            nodes(ids: $ids) {
                ...on Product {
                    id
                    title
                    handle
                    images(first: 3){
                        edges {
                            node {
                            originalSrc
                            transformedSrc(maxWidth: 900)
                                altText
                            }
                        }
                    }
                    metafields(
                        identifiers: [
                            { namespace: "accentuate", key: "template" }
                            { namespace: "accentuate", key: "tiles_per_box" }
                            { namespace: "accentuate", key: "square_feet_box_ratio" }
                            { namespace: "accentuate", key: "linear_feet_box_ratio" }
                            { namespace: "accentuate", key: "shape" }
                            { namespace: "accentuate", key: "length" }
                            { namespace: "accentuate", key: "width" }
                            { namespace: "accentuate", key: "thickness" }
                            { namespace: "customAttributes", key: "select_lead_time" }
                        ]
                    ) {
                        namespace
                        key
                        value
                    }
                    variants(first: 10) {
                        edges {
                            node {
                                availableForSale
                                title
                                id
                                sku
                                metafield (namespace: "accentuate", key: "hex") {
                                    value
                                }
                                price {
                                    amount
                                    currencyCode
                                }
                                compareAtPrice {
                                    amount
                                    currencyCode
                                }
                                selectedOptions {
                                    name
                                    value
                                }
                                quantityAvailable
                                image {
                                    originalSrc
                                    transformedSrc(maxWidth: 900)
                                    altText
                                }
                            }
                        }
                    }
                }
            }
        }
    `;

    const variables = {
        ids,
    };

    const { data } = await axios.post(
        `https://${SHOP_NAME}.myshopify.com/api/2024-04/graphql.json`,
        { query, variables },
        {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'X-Shopify-Storefront-Access-Token': STOREFRONT_ACCESS_TOKEN,
            },
        }
    );

    return {
        ...data,
    };
};

export const getShopifyProductsByTag = async tag => {
    const query = `
        query getShopifyProductsByTag($filter: String!){
            products (first: 250, sortKey: BEST_SELLING, query: $filter) {
                edges {
                    node {
                        id
                        descriptionHtml
                        tags
                        title
                        handle
                        vendor
                        productType
                        metafields(
                            identifiers: [
                                { namespace: "accentuate", key: "h1_primary_title" }
                                { namespace: "heading", key: "convention" }
                                { namespace: "heading", key: "is_mosaic" }
                                { namespace: "accentuate", key: "is_sample" }
                                { namespace: "conspire", key: "sample_variant" }
                                { namespace: "conspire" key: "disable_sample_variant" }
                                { namespace: "accentuate", key: "template" }
                                { namespace: "options", key: "color" }
                                { namespace: "accentuate", key: "acrgowise_product_code" }
                                { namespace: "customAttributes", key: "subtitle" }
                                { namespace: "accentuate", key: "non_tile_pdp" }
                                { namespace: "accentuate", key: "shipping_dimensions" }
                                { namespace: "accentuate", key: "tiles_per_box" }
                                { namespace: "accentuate", key: "split_section_color" }
                                { namespace: "accentuate", key: "square_feet_box_ratio" }
                                { namespace: "accentuate", key: "linear_feet_box_ratio" }
                                { namespace: "accentuate", key: "shape" }
                                { namespace: "accentuate", key: "length" }
                                { namespace: "accentuate", key: "length_short" }
                                { namespace: "accentuate", key: "width" }
                                { namespace: "accentuate", key: "thickness" }
                                { namespace: "customAttributes", key: "select_lead_time" }
                                { namespace: "customAttributes", key: "lead_times" }
                                { namespace: "customAttributes", key: "lead_time" }
                                { namespace: "recd_prod", key: "tag" }
                                { namespace: "recd_prod", key: "products" }
                                { namespace: "accentuate", key: "related_products_custom" }
                            ]
                        ) {
                            namespace
                            key
                            value
                        }
                        images(first: 5){
                            edges {
                                node {
                                    originalSrc
                                    transformedSrc(maxWidth: 900)
                                    altText
                                }
                            }
                        }
                        collections(first: 1){
                            edges {
                                node {
                                    title
                                }
                            }
                        }
                        seo {
                            title
                            description
                        }
                        variants(first:25){
                            edges {
                                node {
                                    availableForSale
                                    title
                                    id
                                    sku
                                    metafield (namespace: "accentuate", key: "hex") {
                                        value
                                    }
                                    price {
                                        amount
                                        currencyCode
                                    }
                                    compareAtPrice {
                                        amount
                                        currencyCode
                                    }
                                    selectedOptions {
                                        name
                                        value
                                    }
                                    quantityAvailable
                                    image {
                                        originalSrc
                                        transformedSrc(maxWidth: 900)
                                        altText
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `;

    const variables = {
        filter: `tag:${tag}`,
    };

    const { data } = await axios.post(
        `https://${SHOP_NAME}.myshopify.com/api/2024-04/graphql.json`,
        { query, variables },
        {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'X-Shopify-Storefront-Access-Token': STOREFRONT_ACCESS_TOKEN,
            },
        }
    );

    return {
        ...data,
    };
};

export const getShopifyProductFirstVariantForCart = async ({ id, handle }) => {
    const query = `
    {
        product(${handle ? `handle: "${handle}"` : `id: "${id}`}) {
            title
            handle
            id
            metafields(
                identifiers: [
                    { namespace: "accentuate", key: "h1_primary_title" }
                    { namespace: "upsell", key: "cart_note" }
                ]
            ) {
                namespace
                key
                value
            }
            collections(first: 5){
                edges {
                    node {
                        title
                        handle
                    }
                }
            }
            variants (first: 1) {
                edges {
                    node {
                        id
                        sku
                        title
                        price {
                            amount
                        }
                        compareAtPrice {
                            amount
                        }
                        image {
                            originalSrc
                            transformedSrc(maxWidth: 900)
                            altText
                        }
                    }
                }
            }
        }
    }`;
    const { data } = await axios.post(
        `https://${SHOP_NAME}.myshopify.com/api/2024-04/graphql.json`,
        { query },
        {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'X-Shopify-Storefront-Access-Token': STOREFRONT_ACCESS_TOKEN,
            },
        }
    );

    return { ...data };
};

export const getShopifyProductSampleByHandle = async handle => {
    const query = `
    {
        product(handle: "${handle}") {
            id
            title
            handle
            variants (first: 1) {
                edges {
                    node {
                        id
                        sku
                        title
                        availableForSale
                        quantityAvailable
                    }
                }
            }
        }
    }`;
    const { data } = await axios.post(
        `https://${SHOP_NAME}.myshopify.com/api/2024-04/graphql.json`,
        { query },
        {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'X-Shopify-Storefront-Access-Token': STOREFRONT_ACCESS_TOKEN,
            },
        }
    );

    return { ...data };
};

export const getShopifyCustomer = accessToken => {
    const query = `
    {
      customer(customerAccessToken: "${accessToken}") {
        id
        tags
      }
    }
   `;
    return axios.post(
        `https://${SHOP_NAME}.myshopify.com/api/2024-04/graphql.json`,
        { query },
        {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'X-Shopify-Storefront-Access-Token': STOREFRONT_ACCESS_TOKEN,
            },
        }
    );
};

// TODO REMOVE ON WISHLIST REMOVE
export const getShopifyProductById = id => {
    const query = `
    {
      node(id: "${id}") {
        id
        ... on Product {
          availableForSale
          id
          title
          handle
          images(first: 1) {
            edges {
              node {
                transformedSrc(maxWidth: 900)
                altText
              }
            }
          }
        }
      }
    }
   `;
    return axios.post(
        `https://${SHOP_NAME}.myshopify.com/api/2024-04/graphql.json`,
        { query },
        {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'X-Shopify-Storefront-Access-Token': STOREFRONT_ACCESS_TOKEN,
            },
        }
    );
};

export const getAlsoComesInProducts = async ({ sku, ids }) => {
    return await axios.get(
        `${process.env.NEXT_PUBLIC_SHOPIFY_UTILS_API}/products/related?${
            ids ? `ids=${JSON.stringify(ids)}` : `sku=${sku}`
        }`
    );
};
