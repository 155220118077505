import PropTypes from 'prop-types';

import { PDPIcon } from './PdpIcon.styled';

const Icon = ({ fill = 'currentColor', ...attrs }) => {
    return (
        <PDPIcon>
            <svg
                className="icon icon--size icon--marble6x6"
                width="91"
                viewBox="0 0 91 91"
                {...attrs}
                fill="none"
                stroke={fill}
                strokeWidth="1"
            >
                <polygon points="29.3,29.4 29.3,89.5 89.5,89.5 89.5,29.6" />
                <polyline points="89.5,26.1 89.5,19.5 29.3,19.5 29.3,26.1" />
                <polyline points="26.1,29.4 19.5,29.4 19.5,89.5 26.1,89.5" />
                <text transform="matrix(1 0 0 1 52.4862 14.5751)">6”</text>
                <text transform="matrix(1 0 0 1 0.9737 59.4397)">6”</text>
            </svg>
        </PDPIcon>
    );
};
Icon.propTypes = {
    fill: PropTypes.string,
};

export default Icon;
