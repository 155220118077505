import PropTypes from 'prop-types';

import { PDPIcon } from './PdpIcon.styled';

const Icon = ({ fill = 'currentColor', ...attrs }) => {
    return (
        <PDPIcon>
            <svg
                className="icon icon--size icon--marbletriangle"
                width="114"
                viewBox="0 0 114 106"
                {...attrs}
                fill="none"
                stroke={fill}
                strokeWidth="1"
            >
                <polygon points="70.3,2.1 28.2,74.6 112.1,74.6" />
                <polyline points="66.8,1.8 17.8,1.8 17.8,74.8 24.6,74.8" />
                <polyline points="28.2,78.3 28.2,85 112.3,85 112.3,78.1" />
                <text transform="matrix(1 0 0 1 1.0576 47.3137)">7”</text>
                <text transform="matrix(1 0 0 1 66.8066 101.5387)">8”</text>
            </svg>
        </PDPIcon>
    );
};
Icon.propTypes = {
    fill: PropTypes.string,
};

export default Icon;
