import PropTypes from 'prop-types';

import { PDPIcon } from './PdpIcon.styled';

const Icon = ({ fill = 'currentColor', ...attrs }) => {
    return (
        <PDPIcon>
            <svg
                className="icon icon--size icon--marble3x126x12"
                width="140"
                viewBox="0 0 140 165"
                {...attrs}
                fill="none"
                stroke={fill}
                strokeWidth="1"
            >
                <rect x="105.6" y="31.6" width="33" height="132" />
                <polyline points="105.6,27.5 105.6,20.4 138.5,20.4 138.5,27.5" />
                <rect x="36.1" y="31.6" width="65.9" height="132" />
                <polyline points="36.1,27.5 36.1,20.4 102,20.4 102,27.5" />
                <polyline points="32.3,163.6 25.2,163.6 25.2,31.6 32.3,31.6" />
                <text transform="matrix(1 0 0 1 0.9801 105.4295)">12”</text>
                <text transform="matrix(1 0 0 1 116.5361 14.5289)">3”</text>
                <text transform="matrix(1 0 0 1 65.6671 14.7076)">6”</text>
            </svg>
        </PDPIcon>
    );
};
Icon.propTypes = {
    fill: PropTypes.string,
};

export default Icon;
