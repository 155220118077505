import PropTypes from 'prop-types';

import { PDPIcon } from './PdpIcon.styled';

const Icon = ({ fill = 'currentColor', ...attrs }) => {
    return (
        <PDPIcon>
            <svg
                className="icon icon--size icon--marblediamond"
                width="92"
                viewBox="0 0 92 65"
                {...attrs}
                fill="none"
                stroke={fill}
                strokeWidth="1"
            >
                <polygon points="23.8,26.6 66.9,26.6 45.1,63.7 2,63.7" />
                <polyline points="68.9,26.6 73.5,26.6 73.5,63.7 47.3,63.7" />
                <polyline points="23.8,24.2 23.8,19.7 66.7,19.7 66.7,24.2" />
                <text transform="matrix(1 0 0 1 78.3723 50.1444)">6”</text>
                <text transform="matrix(1 0 0 1 41.0959 14.3568)">6”</text>
            </svg>
        </PDPIcon>
    );
};
Icon.propTypes = {
    fill: PropTypes.string,
};

export default Icon;
