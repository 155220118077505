import PropTypes from 'prop-types';

import { PDPIcon } from './PdpIcon.styled';

const Icon = ({ fill = 'currentColor', ...attrs }) => {
    return (
        <PDPIcon>
            <svg
                className="icon icon--size icon--marble2x66x12"
                width="102"
                viewBox="0 0 102 190"
                {...attrs}
                fill="none"
                stroke={fill}
                strokeWidth="1"
            >
                <polygon points="35.6,32.9 35.6,163.1 100.7,163.1 100.7,33.1" />
                <polyline points="100.7,29.4 100.7,22.2 35.6,22.2 35.6,29.4" />
                <polyline points="32.2,32.9 25,32.9 25,163.1 32.2,163.1" />
                <polygon points="35.6,166.7 35.6,188.3 100.7,188.3 100.7,166.9" />
                <polyline points="32.2,166.7 25,166.7 25,188.3 32.2,188.3" />
                <text transform="matrix(1 0 0 1 61.6536 14.8065)">6”</text>
                <text transform="matrix(1 0 0 1 0.7723 83.0583)">12”</text>
                <text transform="matrix(1 0 0 1 6.2735 182.9282)">2”</text>
            </svg>
        </PDPIcon>
    );
};
Icon.propTypes = {
    fill: PropTypes.string,
};

export default Icon;
