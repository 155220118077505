'';
import PropTypes from 'prop-types';

import { PDPIcon } from './PdpIcon.styled';

const Icon = ({ fill = 'currentColor', ...attrs }) => {
    return (
        <PDPIcon>
            <svg
                className="icon icon--size icon--marble12x24"
                width="208"
                viewBox="0 0 208 116"
                {...attrs}
                fill="none"
                stroke={fill}
                strokeWidth="1"
            >
                <polyline points="32.8,27.9 32.8,114.9 207,114.9 207,27.9 32.8,27.9" />
                <polyline points="32.8,25.5 32.8,20.8 206.9,20.8 206.9,25.5" />
                <polyline points="30.5,27.9 25.7,27.9 25.7,114.9 30.5,114.9" />
                <text transform="matrix(1 0 0 1 109.9382 14.1738)">24”</text>
                <text transform="matrix(1 0 0 1 0.5432 71.4026)">12”</text>
            </svg>
        </PDPIcon>
    );
};
Icon.propTypes = {
    fill: PropTypes.string,
};

export default Icon;
