import { storyblokEditable } from '@storyblok/react';
import Button from 'components/atoms/Button';
import Link from 'components/atoms/Link';
import RevealElement from 'components/atoms/RevealElement';
import { BodyTextAlt, H0_5 } from 'components/atoms/Typography';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import Hls from 'hls.js';
import Head from 'next/head';

const Organism = styled.section`
    position: relative;
    overflow: hidden;
    height: max(100vh, 450px);
    background-image: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.6) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 0) 60%,
        rgba(0, 0, 0, 0.6) 91%
    );
    transition: all 0.2s ease-in-out;

    @media screen and (max-aspect-ratio: 1/1) {
        height: max(100vh, 500px);
    }

    @supports (height: 100svh) {
        height: max(100svh, 450px);

        @media screen and (max-aspect-ratio: 1/1) {
            height: max(100svh, 500px);
        }
    }

    &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: black;
        z-index: -2;
    }
`;

const VideoContainer = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 100%;
    overflow: hidden;
    z-index: -1;

    video {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

const HeroV3 = ({ blok }) => {
    const sectionRef = useRef(null);
    const data = useMemo(() => {
        const {
            cloudflareCustomerCode,
            cloudflareVideoUID: video,
            mobileCloudflareVideoUID: mobileVideo,
            showMuteControl,
            heading,
            text,
            buttonText,
            buttonLink,
            allowTransparentHeader = true,
        } = blok;

        return {
            cloudflareCustomerCode,
            video,
            mobileVideo,
            showMuteControl,
            heading,
            text,
            buttonText,
            buttonLink,
            allowTransparentHeader,
        };
    }, [blok]);

    return (
        <Organism
            ref={sectionRef}
            className="relative h-screen"
            {...storyblokEditable(blok)}
            {...(data.allowTransparentHeader && { 'data-allow-transparent-header': 'true' })}
        >
            <ResponsiveVideoPlayer
                customerCode={data.cloudflareCustomerCode}
                desktopVideoId={data.video}
                mobileVideoId={data.mobileVideo}
                showMuteControl={data.showMuteControl}
            />
            <div
                className={`container ${
                    data.video
                        ? 'h-full w-full lg:top-auto lg:h-auto absolute top-0 bottom-0 left-1/2 transform -translate-x-1/2'
                        : 'relative'
                } h-full pt-9 pb-9 lg:pb-9 flex flex-col justify-end z-20 pointer-events-none`}
            >
                <div className="banner__header flex flex-col items-start justify-end lg:grid grid-cols-10 gap-6 xl:gap-9">
                    {(data.text || data.heading) && (
                        <div className="col-span-5 lg:col-span-7 grid gap-3">
                            {data.heading && (
                                <RevealElement start="top 100%">
                                    <H0_5
                                        color={data.video ? '#fff' : 'currentColor'}
                                        fontSizeMobile="1.44rem"
                                    >
                                        {data.heading}
                                    </H0_5>
                                </RevealElement>
                            )}
                            {data.text && (
                                <RevealElement delay={0.1} start="top 100%">
                                    <BodyTextAlt
                                        font="heading"
                                        color={data.video ? '#fff' : 'currentColor'}
                                        fontSize="0.875rem"
                                        letterSpacing="0.125rem"
                                    >
                                        {data.text}
                                    </BodyTextAlt>
                                </RevealElement>
                            )}
                        </div>
                    )}
                    {data.buttonText && (
                        <RevealElement
                            delay={0.2}
                            className="col-span-full lg:col-span-3 lg:flex lg:justify-end lg:self-end"
                            start="top 100%"
                        >
                            <Button
                                as={Link}
                                href={data.buttonLink || '#'}
                                className="pointer-events-auto"
                                inverted={data.video ? true : false}
                                label={data.buttonText}
                                fontWeight="500"
                            />
                        </RevealElement>
                    )}
                </div>
            </div>
        </Organism>
    );
};

HeroV3.propTypes = {
    blok: PropTypes.object,
};

export default HeroV3;

const ResponsiveVideoPlayer = ({
    customerCode,
    desktopVideoId,
    mobileVideoId,
    showMuteControl,
}) => {
    const [isDesktop, setIsDesktop] = useState(false);

    useEffect(() => {
        // This function sets the viewport size state
        const handleResize = () => {
            setIsDesktop(window.matchMedia('(min-width: 640px)').matches);
        };

        // Initialize viewport state on component mount
        handleResize();

        // Add resize event listener
        window.addEventListener('resize', handleResize);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const videoSrcs = useMemo(() => {
        return {
            desktop: `https://customer-${customerCode}.cloudflarestream.com/${desktopVideoId}/manifest/video.m3u8`,
            mobile: `https://customer-${customerCode}.cloudflarestream.com/${mobileVideoId}/manifest/video.m3u8`,
        };
    }, [customerCode, desktopVideoId, mobileVideoId]);

    return (
        <div>
            <Head>
                <link
                    rel="preconnect"
                    href={`https://customer-${customerCode}.cloudflarestream.com`}
                />
                <link
                    rel="preload"
                    href={`https://customer-${customerCode}.cloudflarestream.com/${
                        isDesktop ? desktopVideoId : mobileVideoId
                    }/manifest/video.m3u8`}
                    as="fetch"
                />
            </Head>
            <VideoPlayer
                src={isDesktop ? videoSrcs.desktop : videoSrcs.mobile}
                showMuteControl={showMuteControl}
            />
        </div>
    );
};

ResponsiveVideoPlayer.propTypes = {
    customerCode: PropTypes.string,
    desktopVideoId: PropTypes.string,
    mobileVideoId: PropTypes.string,
    showMuteControl: PropTypes.bool,
};

const VideoPlayer = ({ src, showMuteControl }) => {
    const videoRef = useRef(null);
    const [isMuted, setIsMuted] = useState(true);
    const [showControls, setShowControls] = useState(false);
    const hideControlsTimeoutRef = useRef(null);

    useEffect(() => {
        setIsMuted(true);
        if (videoRef.current && Hls.isSupported()) {
            const hls = new Hls();
            const videoElement = videoRef.current;

            hls.attachMedia(videoElement);

            hls.on(Hls.Events.MEDIA_ATTACHED, () => {
                hls.loadSource(src);
            });

            hls.on(Hls.Events.MANIFEST_PARSED, () => {
                // Attempt to play video
                videoElement.muted = true; // Ensure video is muted
                videoElement.play().catch(error => {
                    console.error('Error attempting to play video:', error);
                });
            });

            return () => hls.destroy();
        }
    }, [src]);

    const toggleMute = () => {
        const videoElement = videoRef.current;
        if (videoElement) {
            videoElement.muted = !videoElement.muted;
            setIsMuted(videoElement.muted);
        }
    };
    const handleShowControls = () => {
        setShowControls(true);
        if (hideControlsTimeoutRef.current) {
            clearTimeout(hideControlsTimeoutRef.current);
        }
    };

    const handleHideControls = () => {
        hideControlsTimeoutRef.current = setTimeout(() => {
            setShowControls(false);
        }, 3000); // Hide controls after 3 seconds
    };

    return (
        <div
            onMouseEnter={handleShowControls}
            onMouseLeave={handleHideControls}
            onTouchStart={handleShowControls}
            onTouchEnd={handleHideControls}
        >
            <VideoContainer>
                {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                <video
                    ref={videoRef}
                    disablePictureInPicture
                    controls={false}
                    playsInline={true}
                    loop={true}
                />
            </VideoContainer>
            {showMuteControl && (
                <button
                    className="absolute w-full h-full top-0 left-0 items-center justify-end container max-w-full flex z-10 visible"
                    onClick={toggleMute}
                    aria-label="Toggle Mute"
                >
                    <div
                        className={`w-12 h-12 p-3 overflow-hidden rounded-full bg-black/25 transition-all ${
                            showControls
                                ? 'visible opacity-100 translate-y-0'
                                : 'invisibl opacity-0 translate-y-full'
                        }`}
                    >
                        <svg
                            className={`w-full h-full text-white`}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 18.4 18.38"
                        >
                            <path
                                d="M12.62,1.2c7,4.44,7,11.65,0,16.1h0m0-13.38c3.74,2.91,3.74,7.62,0,10.53M5,5.44,9.72.72A.75.75,0,0,1,11,1.25V17.13a.75.75,0,0,1-1.28.53L5,12.94H2.76A2,2,0,0,1,.82,11.59,9.31,9.31,0,0,1,.5,9.19a9.08,9.08,0,0,1,.32-2.4A2,2,0,0,1,2.76,5.44Z"
                                fill="none"
                                stroke="currentColor"
                            />
                            <line
                                className="transition-all"
                                x1="0.5"
                                y1="0.5"
                                x2="17.9"
                                y2="17.88"
                                fill="none"
                                strokeDasharray="25"
                                strokeDashoffset={isMuted ? 0 : 25}
                                stroke="currentColor"
                            />
                        </svg>
                    </div>
                </button>
            )}
        </div>
    );
};

VideoPlayer.propTypes = {
    src: PropTypes.string,
    showMuteControl: PropTypes.bool,
};
