import PropTypes from 'prop-types';

import { PDPIcon } from './PdpIcon.styled';

const Icon = ({ fill = 'currentColor', ...attrs }) => {
    return (
        <PDPIcon>
            <svg
                className="icon icon--size icon--marble12x12"
                width="165"
                viewBox="0 0 165 161"
                {...attrs}
                fill="none"
                stroke={fill}
                strokeWidth="1"
            >
                <polygon points="35.3,31.5 35.3,159.5 163.5,159.6 163.5,31.7" />
                <polyline points="163.5,27.4 163.5,20.4 35.9,20.4 35.9,27.4" />
                <polyline points="31.9,31.5 24.9,31.5 24.9,159.5 31.9,159.5" />
                <text transform="matrix(1 0 0 1 91.6456 14.55)">12”</text>
                <text transform="matrix(1 0 0 1 0.9585 99.8635)">12”</text>
            </svg>
        </PDPIcon>
    );
};
Icon.propTypes = {
    fill: PropTypes.string,
};

export default Icon;
